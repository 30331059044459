import React from 'react'

export default function Pad(props) {
  let editingNotes = props.editingNotes,
      note = props.note

  let isSequenced = editingNotes ? editingNotes.includes(note) : false
  /* console.log(isSequenced) */

  return (
    window.matchMedia('(hover: hover) and (pointer: fine)').matches ?
      <button 
        className={isSequenced ? "Pad sequenced" : "Pad"}
        onMouseDown={e => props.handleClick(props.note)}
        data-note={note}
        data-device={"non-touch"}
        data-sequenced={isSequenced ? true : false}
      >
        {props.children}
      </button>
      :
      <button 
        className={isSequenced ? "Pad sequenced" : "Pad"}
        onTouchStart={e => props.handleClick(props.note)}
        data-note={note}
        data-device={"touch"}  
        data-sequenced={isSequenced ? true : false}
      >
        {props.children}
      </button>
  )
}
