import React from 'react'

import './Beat.css'

export default function Beat(props) {
  const index = props.index,
        notesArray = props.notesArray[1],
        tuningArray = props.tuningArray,
        playingIndex = props.playingBeatIndex,
        editingIndex = props.editingIndex,
        setEditingIndex = props.setEditingIndex

  const renderPad = (note, index) => {
    let isNoteSequenced = notesArray.includes(note)
    
    if (isNoteSequenced) {
      return <span key={index} className="beat-pad sequenced" data-note={note}></span>
    } else {
      return <span key={index} className="beat-pad" data-note={note}></span>
    }
  }

  let classList = ["Beat"]
  if (editingIndex === index) { classList.push("editing")}
  if (playingIndex === index) { classList.push("playing")}
  classList = classList.join(" ")
  
  return (
    <div 
      className={classList}
      data-index={index}
      onClick={e => {
        if (editingIndex === index) {
          setEditingIndex(-1)
        } else {
          setEditingIndex(index)
        }
      }}
    >
      {tuningArray.map((note, index) => {
        return renderPad(note, index)
      })}
    </div>
  )
}
