import React from 'react'
import PropTypes from 'prop-types';

import './css/Stack.css'


/**
 * A layout primitive producing a traditional stack, either vertical or horizontal.
 * 
 * ``` js
 * <Stack orientation={""} spacing={""} > {children} </Stack>
 * ```
 * @prop {string} orientation - Determines the orientation of the stack. Accepts "vertical" or "horizontal".
 * @prop {string} spacing - Semantic margin between children. "tiny" (4px), "small" (8px), "medium" (16px), "large" (32px), or "huge" (64px)
 */


const Stack = React.forwardRef((props, ref) => {
  let orientation = props.orientation ? props.orientation + " " : "vertical ",
          spacing = props.spacing ? props.spacing + " " : "",
        className = props.className ? props.className  + " " : "",
           center = props.center ? "center" : "",
           wrap = props.wrap ? " wrap" : "";

  return (
    <div 
      ref={ref} 
      id={props.id ? props.id : ""} 
      className={"Stack " + orientation + spacing + className + center + wrap} 
      style={props.style} 
      onClick={props.onClick} 
      onDragOver={props.onDragOver} 
      onDragLeave={props.onDragLeave} 
      onDrop={props.onDrop}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      data-view={props.dataView}
    >
      {props.children}
    </div>
  )
})

Stack.propTypes = {
  /** Semantic margin between children. Accepts "tiny" (4px), "small" (8px), "medium" (16px), "large" (32px), or "huge" (64px)
   * ``` js
   * spacing={"tiny"}
   * ```
  */
  spacing: PropTypes.string,
  /** Determines the orientation of the stack. Accepts "vertical" or "horizontal".
   * ``` js
   * orientation={"vertical"}
   * ```
  */
  orientation: PropTypes.string,
}

export default Stack;